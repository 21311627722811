<template>
  <div v-loading.fullscreen="loading" element-loading-spinner="el-icon-loading" :element-loading-text=loadingText>
 <!-- payment schedule window -->
    <div
      class="grid sm:fixed w-xs justify-self-end grid-cols-1 right-5 sm:z-20"
      v-if="showError == false 
      && !isSoldOut 
      && formFields.type 
      || (isSoldOut & formFields.type == 'pay') 
      || (isSoldOut 
      && hasPreRegistrations 
      && formFields.type 
      && formFields.type != 'waitlist')"
    >
      <div class="border shadow-md text-white z-0 rounded text-right p-2 bg-muench bg-opacity-80">
        <div class="border-b-1 border-dotted w-65 max-w-lg">
          <p class="text-center pb-2 mx-4 mt-2 font-medium">Payment Schedule</p>
          <p v-if="hasCabins && formFields.type == 'register' && !this.isSoldOut" class="text-center pb-2 font-medium break-words">Cabin: {{cabin[formFields.selectedCabin].desc}}</p>
          <!--Discount-->
          <div class="break-words">
            <p v-if="inSalePeriod && (formFields.type != 'pay')" class="text-center pb-2 font-light text-sm ">{{ onlineSaleDescription }} discount of ${{ onlineSaleRate }} will be applied to your final payment</p>
          </div>
        </div>
        <table class="w-full font-light text-sm leading-tight whitespace-pre-wrap text-left ml-6 mr-2 mb-4 mt-2 min-w-xs " >
          <tr >
            <th class="font-medium ">Payment</th>
            <th></th>
            <th class="font-medium ">Due</th>
          </tr>

          <!--first payment-->
          <tr v-if="depositAmount > 0 && depositAmount != '' && !isSinglePayment" class="min-w-xs">
            <td>
              <span v-if=" balanceAmount > 0 && balanceAmount != '' " >Deposit</span>
              <span v-else >Full Amount</span>
            </td>

            <!-- <td> -->
              <transition name="slide-fade" mode="out-in">
                <td v-if="!hasCabins" :key="depositDisplay">
                  {{ depositDisplay }}
                </td>
                <td v-else></td>
              </transition>

            <!-- </td> -->
            <td>At Sign-Up </td>
          </tr>
          <tr v-else class="min-w-xs">
            <td>
              <span>Tuition</span>
            </td>
            <td>
              <transition name="slide-fade" mode="out-in"><span :key="depositDisplay">{{ depositDisplay }}</span></transition>
            </td>
            <td>At Sign-Up </td>
          </tr>

          <!--second payment-->
          <tr v-if=" secondPaymentAmount > 0 && secondPaymentAmount != '' && !isSinglePayment" class=" min-w-xs ">
            <td><span>Second</span> </td>
            <transition name="slide-fade" mode="out-in">
              <td v-if="!hasCabins" :key="secondPaymentDisplay">
                {{secondPaymentDisplay}}
              </td>
              <td v-else></td>
            </transition>
            <td>{{ secondPaymentDueDate }} </td>
          </tr>

          <!--balance payment-->
          <tr v-if=" balanceAmount > 0 && balanceAmount != '' && !isSinglePayment">
            <td><span>Final</span> </td>
            <transition name="slide-fade" mode="out-in">
              <td v-if="!hasCabins" :key="balanceDisplay">
                {{ balanceDisplay }}
              </td>
              <td v-else></td>
            </transition>
            <td>{{ balanceDueDate }} </td>
          </tr>
        </table>
      </div>

      <transition name="slide-fade" mode="out-in">
        <div v-if="formFields.paymentMethod == 'credit'" :key="formFields.paymentMethod" class="mt-2 border shadow-md text-white rounded text-center p-2 bg-muench bg-opacity-80 ">
          <div >
            <p class="my-2 font-medium border-b-1 border-dotted mx-4 pb-2">Paying Now</p>
            <div v-if="formFields.type == 'register'">
              <transition name="slide-fade" mode="out-in">
                <div :key="itemsDisplay" class="text-sm  whitespace-pre-wrap">{{ itemsDisplay }}</div>
              </transition>
              <div class="flex justify-center"><hr class="border-white border-dotted my-2 w-3/4 " /></div>
            </div>
            <div class=" font-medium ">
              Total: <transition name="slide-fade" mode="out-in"><span :key="amountPayingDisplay" class=" font-normal ">{{ amountPayingDisplay }}</span></transition>
            </div>
            <transition name="slide-fade" mode="out-in"><div :key="paymentMethodNote" class="text-xs mb-4">{{ paymentMethodNote }}</div></transition>
          </div>
        </div>
      </transition>
    </div>


  <!-- header -->
  <div>
    <div class="flex justify-center mt-8">
    <div class="flex items-center space-x-8 max-w-4xl p-4 md:w-4/5 text-left">
      <img
        class=" h-24"
        src="../../src/assets/Logo_orange_text-mainlogo.jpg"
        alt="logo"
      />
      <p class="muench text-2xl font-light pt-4">Registration & Payments</p>
    </div>
    </div>
  <router-view />
  </div>


    <!--PAYMENT MODAL-->
    <el-dialog :title= "paymentModalTitle" :visible.sync="showStripeModal" :width="windowWidth > 600 ? '50%' : '100%'">
      <div class="ml-8 mb-4">
        <p class=" font-semibold pb-2 flex justify-center">Paying Now</p>
        <div v-if="formFields.type =='register'">
          <div class="pl-2 text-sm flex justify-center whitespace-pre-wrap">{{ itemsDisplay }}</div>
          <div class="flex justify-center"><hr class="border-gray-400 border-dotted my-2 w-2/3 " /></div>
        </div>
        <div class=" pl-2 font-semibold flex justify-center space-x-2">
          <span>Total: </span><span class=" font-normal "> {{ amountPayingDisplay }}</span>
        </div>
      </div>
        <final v-if="showStripeModal" :formFields="formFields" :paymentIntent="paymentIntent" :title="this.title" :rules="rules" ref="final"  v-loading="loading" @close-payment="closePayment"/>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>


    <!-- REGISTRATION MODAL: Show this if email is found during registration   -->
    <el-dialog :visible.sync="showRegistrationModal" >
      <div >
        <p class=" font-semibold mb-4 flex justify-center">Already Registered!</p>
        <p class="mx-4 flex justify-center whitespace-pre-wrap">There is already a registration for this workshop using this email.
          <br>Would you like to make a payment towards that registration or create a new one?
        </p>
      </div>
      <span slot="footer" class="dialog-footer flex mx-4 justify-center">
        <el-button @click="handleExistingRegistration('register')">Another Registration</el-button>
        <el-button type="primary" @click="handleExistingRegistration('pay')">Make a Payment</el-button>
      </span>
    </el-dialog>
  

    <!-- main window -->
    <div class="flex justify-center " v-if="showError == false">
      <div class="border lg:max-w-4xl p-4 rounded shadow-md mb-4 w-full md:w-4/5 text-left bg-white z-10 ">
        <p class="muench font-bold text-xl lg:text-3xl lg:pl-2">{{ title }}</p>
        <p class="font-semibold lg:text-lg lg:pl-3" v-if="subtitle != ''">
          {{ subtitle }}
        </p>
        <p class="font-semibold pl-3 pb-2">{{ tripDate }}</p>
        <div class="flex " v-if="showSpotsLeft">
          <p class="bg-muench  text-white ml-2 text-center rounded-sm w-44 mb-2">{{ spotsLeftLabel }}</p>
        </div>
        <div class="flex " v-if="comingSoon">
          <p class="bg-muench  text-white ml-2 text-center rounded-sm w-44 mb-2">Coming Soon</p>
        </div>
        <hr class="border border-dotted mb-4" />
        <div v-if=" balanceAmount > 0 && balanceAmount != '' || isSinglePayment" >
          <div class="pl-6 block mb-4">

            <!-- Wailist -->
            <div v-if="isSoldOut && !comingSoon" class="whitespace-pre-line leading-tight ">
              <div>
                <div @click="updateDisplayValues(true)">
                  <el-radio v-model="formFields.type" label="waitlist" class="pl-2 my-1"
                    >I want to be added to the waitlist for this workshop.</el-radio>
                </div>
                <div @click="updateDisplayValues(true)">
                  <el-radio v-model="formFields.type" label="pay" class="pl-2 my-1"
                    >I want to make a payment. I’m already registered.</el-radio>
                </div>
                <div @click="updateDisplayValues(false)">
                  <el-radio  v-if="hasPreRegistrations" v-model="formFields.type" label="register" class="pl-2 my-1"
                    >You’re holding a spot for me and I need to register.</el-radio>
                </div>
              </div>
              <div v-if="formFields.type == 'register' && !hasPreRegistrations || formFields.type == 'waitlist'" class="mt-3">
                {{soldOutText}}
              </div>
            </div>

            <!-- Coming Soon -->
            <div v-if="comingSoon" class="whitespace-pre-line leading-tight ">
              {{comingSoonText}}
            </div>

            <!-- Registration -->
            <div v-if="!isSoldOut && !comingSoon">
              <p class="font-medium">I would like to:</p>
              <div @click="updateDisplayValues(false)" >
                <el-radio v-model="formFields.type" label="register" class="pl-2"
                  >Register for this workshop.</el-radio>
              </div>
              <div @click="updateDisplayValues(true)" >
                <el-radio v-model="formFields.type" label="pay" class="pl-2"
                  >Make a payment (I am already registered).</el-radio>
              </div>
            </div>
          </div>
          <hr class="border border-dotted mb-4" />
        </div>

        <el-form
          v-if="formFields.type || comingSoon"
          :model="formFields"
          :rules="rules"
          ref="formFields"
          class="w-full mt-2 text-left space-y-2"
        >
          <!-- Main Selection - Visible on both Payment and Register routes-->
          <div class="flex justify-center">
            <div class="block w-full lg:mx-8 space-y-2">
              <p class="font-medium pl-2"> Name <span class="text-red-500">*</span></p>
              <div class="flex lg:flex-row flex-col lg:space-x-2 w-full">
                <el-form-item prop="fname" class="lg:w-1/2">
                  <el-input placeholder="First" v-model="formFields.fname"></el-input>
                </el-form-item>

                <el-form-item prop="lname" class="block lg:w-1/2">
                  <el-input placeholder="Last" v-model="formFields.lname"
                  ></el-input>
                </el-form-item>
              </div>


             <!--SOLD OUT- SHOW WAITLIST-->
              <div v-if="formFields.type == 'waitlist' || comingSoon">
                <div class=" w-full space-x-2 flex">
                  <div class="w-1/2">
                    <p class="font-medium pl-2"> Email <span class="text-red-500">*</span></p>
                    <el-form-item prop="email">
                      <el-input placeholder="Email" v-model="formFields.email">   
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="w-1/2">
                  <div class="flex items-center">
                    <p class="font-medium pl-2">
                      Cell Phone <span class="text-red-500">*</span>
                    </p>
                    <el-checkbox class="ml-10" v-model="formFields.isCellIntl">International</el-checkbox>
                  </div>
                  <div v-if="formFields.isCellIntl" class="flex flex-col">
                    <el-autocomplete
                        class="inline-input mb-2"
                        v-model="formFields.cellPrefixCountry"
                        :fetch-suggestions="querySearchPhone"
                        placeholder="Select Country"
                    ></el-autocomplete>
                    <el-form-item prop="cell" class="w-full">
                      <el-input
                        placeholder="Enter Number"
                        v-mask="'###############'"
                        v-model="formFields.cell"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>
                    <el-form-item prop="cell">
                      <el-input
                        placeholder="(xxx) xxx-xxxx"
                        v-mask="'(###) ###-####'"
                        v-model="formFields.cell"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                </div>
                <div v-if="formFields.type == 'pay'" class="flex mt-3">
                  <div class="w-full">
                    <p class="font-medium pl-2">Amount to Pay (USD) <span class="text-red-500"> *</span></p>
                    <el-form-item prop="differentDollar">
                      <el-input
                        v-mask="currencyMask"
                        v-model="formFields.differentDollar"
                        placeholder="$USD"
                        class="w-full"
                      ></el-input>
                    </el-form-item>
                  </div>                  
                </div>
                <div class="font-medium">
                  <p class="font-medium">Message (Optional) </p>
                  <el-form-item class="w-full">
                    <el-input
                      v-model="formFields.waitlistNote"
                      type="textarea"
                      rows="4">
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flex w-full justify-center py-4">
                  <el-button v-if="formFields.type == 'pay'"
                    type="primary"
                    @click="openPaymentValidate('formFields')"
                    >Continue</el-button
                  >
                  <el-button v-else
                    type="primary"
                    @click="register('formFields')"
                    >Send</el-button
                  >
                </div>
              </div>

              <!--NEW REGISTRATION-->
              <div v-else-if="formFields.type == 'register'" class="block space-y-3">
                <div>
                <p class="font-medium pl-2">Email <span class="text-red-500">*</span>
                </p>
                <el-form-item prop="email">
                  <el-input placeholder="Email" v-model="formFields.email"></el-input>
                </el-form-item>
              </div>
                <p class="font-medium pl-2">Address <span class="text-red-500">*</span></p>
                <div class="space-y-1">
                  <el-form-item prop="addr1">
                    <el-input placeholder="Street Address" v-model="formFields.addr1"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="addr2">
                    <el-input placeholder="Address Line 2" v-model="formFields.addr2"></el-input>
                  </el-form-item>

                  <div class="flex space-x-2 pt-4">
                    <el-form-item prop="city" class="w-1/2">
                      <el-input placeholder="City" v-model="formFields.city"></el-input>
                    </el-form-item>

                    <el-form-item prop="state" class="w-1/2">
                      <el-input placeholder="State/Province" v-model="formFields.state"></el-input>
                    </el-form-item>
                  </div>

                  <div class="flex space-x-2 w-full">
                    <el-form-item prop="zip" class=" w-1/2">
                      <el-input placeholder="Postal/Zip Code" v-model="formFields.zip"></el-input>
                    </el-form-item>

                    <el-form-item prop="country" class=" w-1/2">
                      <el-select v-model="formFields.country" placeholder="Country">
                        <el-option
                          v-for="item in countryList"
                          :key="item.label"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div>
                  <div class="flex flex-col lg:flex-row lg:space-x-2 w-full">
                    <div class="lg:w-1/2">
                      <div class="flex items-center">
                        <p class="font-medium  pl-2">Cell Phone <span class="text-red-500">*</span></p>
                        <el-checkbox class="ml-10" v-model="formFields.isCellIntl">International</el-checkbox>
                      </div>
                      <div v-if="formFields.isCellIntl" class="flex flex-col">
                        <el-autocomplete
                          class="inline-input mb-2"
                          v-model="formFields.cellPrefixCountry"
                          :fetch-suggestions="querySearchPhone"
                          placeholder="Select Country"
                        ></el-autocomplete>
                        <el-form-item prop="cell" class="w-full">
                          <el-input
                            placeholder="Enter Number"
                            v-mask="'###############'"
                            v-model="formFields.cell"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>
                        <el-form-item prop="cell">
                          <el-input
                            placeholder="(xxx) xxx-xxxx"
                            v-mask="'(###) ###-####'"
                            v-model="formFields.cell"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="lg:w-1/2">
                      <div class="flex items-center">
                        <p class="font-medium  pl-2">Alternate Phone</p>
                        <el-checkbox class="ml-10" v-model="formFields.isAltIntl">International</el-checkbox>
                      </div>
                      <div v-if="formFields.isAltIntl" class="flex flex-col">
                        <el-autocomplete
                          class="inline-input mb-2"
                          v-model="formFields.altPhonePrefixCountry"
                          :fetch-suggestions="querySearchPhone"
                          placeholder="Select Country"
                        ></el-autocomplete>
                        <el-input
                          class="w-full"
                          placeholder="Enter Number"
                          v-mask="'###############'"
                          v-model="formFields.altPhone"
                        ></el-input>
                      </div>
                      <div v-else>
                        <el-input
                          placeholder="(xxx) xxx-xxxx"
                          v-mask="'(###) ###-####'"
                          v-model="formFields.altPhone"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p class="font-medium pl-2">Birthdate <span class="text-red-500">*</span></p>
                  <div class="flex space-x-2 lg:w-2/4">
                    <el-form-item prop="dobMonth" class="block w-1/3">
                      <el-input
                        ref="dobMonth"
                        placeholder="MM"
                        v-mask="'##'"
                        v-model="formFields.dobMonth"
                      ></el-input>
                    </el-form-item>
                    <el-form-item prop="dobDay" class="block w-1/3">
                      <el-input
                        ref="dobDay"
                        placeholder="DD"
                        v-mask="'##'"
                        v-model="formFields.dobDay"
                      ></el-input>
                    </el-form-item>
                    <el-form-item prop="dobYear" class="block w-1/3">
                      <el-input
                        ref="dobYear"
                        placeholder="YYYY"
                        v-mask="'####'"
                        v-model="formFields.dobYear"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div>
                  <p class="font-medium pl-2">Emergency Contact
                    <span class="text-sm font-normal px-2">(Someone who will not be on the workshop with you)</span>
                    <span class="text-red-500">*</span>
                  </p>
                  <div class="flex space-x-2 w-full">
                    <el-form-item prop="contactFirst" class="block w-full">
                      <el-input placeholder="First & Last Name" v-model="formFields.contactFirst"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="lg:w-1/2">
                  <div class="flex items-center">
                    <p class="font-medium  pl-2">Emergency Contact Phone<span class="text-red-500">*</span></p>
                    <el-checkbox class="ml-10" v-model="formFields.isContactIntl">International</el-checkbox>
                  </div>
                  <div v-if="formFields.isContactIntl" class="flex flex-col w-full">
                    <el-autocomplete
                      class="inline-input mb-2"
                      v-model="formFields.contactPhonePrefixCountry"
                      :fetch-suggestions="querySearchPhone"
                      placeholder="Select Country"></el-autocomplete>
                    <el-form-item prop="contactPhone" class="w-full">
                      <el-input
                        placeholder="Enter Number"
                        v-mask="'###############'"
                        v-model="formFields.contactPhone"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item prop="contactPhone" v-else>
                    <el-input
                      placeholder="(xxx) xxx-xxxx"
                      v-mask="'(###) ###-####'"
                      v-model="formFields.contactPhone"
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="flex items-center" :class="{ invalid: isInvalid.fitnessLevel }">
                  <div class="w-1/2 flex-1">
                    <p class="font-medium pl-2">Fitness Level <span class="text-red-500">*</span></p>
                    <el-form-item prop="fitnessLevel" class="flex justify-left px-4">
                      <el-radio v-model="formFields.fitnessLevel" label="easy">Easy</el-radio>
                      <el-radio v-model="formFields.fitnessLevel" label="moderate">Moderate</el-radio>
                      <el-radio v-model="formFields.fitnessLevel" label="difficult">Difficult</el-radio>
                      <el-radio v-model="formFields.fitnessLevel" label="challenging">Challenging</el-radio>
                    </el-form-item>
                  </div>
                  <transition name="slide-fade" mode="out-in">
                    <div class=" text-gray-500 flex-initial" :key="fitnessDescription">
                      {{ fitnessDescription }}
                    </div>
                  </transition>
                </div>

                <div v-if="specialFitness">
                  <span class=" font-medium  pl-2">Fitness Advisory</span>
                  <el-form-item
                    prop="agreeToFitness"
                    class="pl-4 mb-2"
                    :class="{ invalid: isInvalid.agreeToFitness }"
                  >
                    <p>This workshop is rated as {{fitnessLevel}}:</p>
                    <p class="whitespace-pre-line leading-tight ml-2 pt-2">{{ specialFitness }}</p><br>
                    <el-checkbox
                      v-model="formFields.agreeToFitness"
                      class=" pl-5"
                      >By registering for this workshop you confirm that your fitness and health meet these criteria. <span class="text-red-500 text-lg">*</span></el-checkbox>
                  </el-form-item>
                </div>

                <div>
                  <p class="font-medium pl-2">Please describe your fitness level.<span class="text-red-500">*</span></p>
                  <el-form-item prop="fitnessDetail" class="pb-2">
                    <el-input
                      v-model="formFields.fitnessDetail"
                      type="textarea"
                      rows="4"
                    ></el-input>
                  </el-form-item>
                </div>

                <p class="font-medium pl-2">Please list any dietary restrictions and food allergies.<span class="text-red-500">*</span></p>
                <el-form-item prop="dietaryRequirements" class="pb-2">
                  <el-input
                    v-model="formFields.dietaryRequirements"
                    type="textarea"
                    rows="4"
                  ></el-input>
                </el-form-item>

                <p class="font-medium pl-2">Please list all medical conditions, medications, or concerns you have that we should know about.<span class="text-red-500">*</span></p>
                <el-form-item prop="medicalRequirements" class="pb-2">
                  <el-input
                    v-model="formFields.medicalRequirements"
                    type="textarea"
                    rows="4"
                  ></el-input>
                </el-form-item>

                <p class="font-medium pl-2">
                  Do you have any questions or is there something you want
                  to let us know? 
                </p>
                <el-form-item prop="otherInfo" class="pb-4">
                  <el-input
                    v-model="formFields.otherInfo"
                    type="textarea"
                    rows="4"
                  ></el-input>
                </el-form-item>

                <div class="grid grid-cols-1 space-y-2">
                  <p class="text-base pl-2">
                    How well do you know your camera?
                    <span class="text-red-500">*</span>
                  </p>
                  <el-form-item prop="cameraKnowledge" class="pl-4">
                    <div class="w-full">
                      <el-radio v-model="formFields.cameraKnowledge" label="beginner"><b>Beginner:</b>
                        <p class="pl-8 text-xs lg:text-sm">
                          I need help with basic camera functions. 
                        </p>
                      </el-radio>
                    </div>
                    <div class="w-full">
                      <el-radio v-model="formFields.cameraKnowledge" label="intermediate"><b>Intermediate:</b>
                        <p class="pl-8 text-xs lg:text-sm">
                          I’m starting to get it, but still need help.
                        </p>
                      </el-radio>
                    </div>
                    <div class="w-full">
                      <el-radio v-model="formFields.cameraKnowledge" label="advanced" class="w-1/2"><b>Advanced:</b>
                        <p class="pl-8 lg:text-sm">
                          I know and understand my camera’s buttons and menus inside and out, <br/> I can set and change all functions on my camera without assistance.
                        </p>
                      </el-radio>
                    </div>
                  </el-form-item>
                </div>
                <div>
                  <div v-if="showCabin" class="">
                    <hr class="border border-dotted my-4" />
                    <p class="text-xl font-medium py-2 pl-2 inline">Cabins</p>
                    <p class="text-md py-2 pl-2 inline">(Select Preferred Cabin)</p>
                      <el-table
                        ref="singleTable"
                        :data="cabin"
                        highlight-current-row
                        @current-change="handleCurrentChange"
                        style="width: 100%">
                          <el-table-column
                            prop="desc"
                            label="Cabin"
                            width="200">
                          </el-table-column>
                          <el-table-column
                            prop="total"
                            label="Tuition"
                            width="120">
                            <template slot-scope="props">
                              {{ toCurrency(props.row.total) }}
                            </template>
                          </el-table-column>                        
                          <el-table-column
                            property="depositAmount"
                            label="Deposit"
                            width="120">
                            <template slot-scope="props">
                              {{ toCurrency(props.row.depositAmount) }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="secondPaymentAmount"
                            label="Further Payment"
                            width="140">
                            <template slot-scope="props">
                              {{ toCurrency(props.row.secondPaymentAmount) }}
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="balanceAmount"
                            label="Balance Payment"
                            width="140">
                            <template slot-scope="props">
                              {{ toCurrency(props.row.balanceAmount) }}
                            </template>                          
                          </el-table-column>                        
                      </el-table>
                  </div>
                  <div v-if="SRSType === 'SRS'" class="pl-2 mt-2">
                        <p class="font-medium">What's your rooming preference? <span class="text-red-500"> *</span></p>
                        <el-form-item prop="noncreditSRS" :class="{ invalid: isInvalid.noncreditSRS }">
                          <div class="w-full ml-2">
                            <el-radio v-model="formFields.noncreditSRS" label="double">Double (you will be assigned a roommate)</el-radio>
                          </div>
                          <div class="w-full ml-2">
                            <el-radio v-model="formFields.noncreditSRS" label="single">
                              Single Room Supplement (additional {{singleDisplay}} required. {{ singleOccupancySupplementNote }})
                            </el-radio>
                          </div>
                        </el-form-item>
                    </div>
                  <p class="text-xl font-medium pb-2 m-2 pl-2">Payments
                    <transition name="slide-fade" mode="out-in">
                      <span :key="cabin[formFields.selectedCabin].desc" v-if="cabinQty > 1">: {{cabin[formFields.selectedCabin].desc}}</span>
                    </transition>
                  </p>
                  <div class="flex">
                    <div class="lg:w-1/2">
                      <p class="pl-4 pt-2 font-medium">
                        How would you like to pay?
                        <span class="text-red-500">*</span>
                      </p>
                      <el-form-item
                        prop="paymentMethod"
                        class="pl-6"
                        :class="{ invalid: isInvalid.paymentMethod }"
                      >
                        <div class="h-4 my-3" @click="updateDisplayValues(false)">
                          <el-radio
                            v-model="formFields.paymentMethod"
                            label="credit"
                            >Now with credit card.</el-radio
                          >
                        </div>
                        <div class="h-4 my-3" @click="updateDisplayValues(false)">
                          <el-radio
                            v-model="formFields.paymentMethod"
                            label="check"
                            >By check.</el-radio
                          >
                        </div>
                        <div class="h-4 my-3" @click="updateDisplayValues(false)">
                          <el-radio                            
                            v-model="formFields.paymentMethod"
                            label="other"
                            >I would like to make other payment
                            arrangements.</el-radio
                          >
                        </div>
                      </el-form-item>
                      <div v-if="windowWidth < 500" class="w-full mt-2">
                      <transition name="slide-fade" mode="out-in">
                        <div>
                          <div v-if="formFields.paymentMethod == 'check'" :key="formFields.paymentMethod" class="pl-2 w-full">
                          <p class="font-medium">We will email you instructions to pay by check.</p>
                          </div>
                          <div v-if="formFields.paymentMethod == 'other'" :key="formFields.paymentMethod" class="pl-2">
                           <p class="font-medium">Please tell us about the arrangements you would like to make. <span class="text-red-500">*</span></p>
                            <el-form-item prop="isPayingOtherNote" class="w-full">
                              <el-input
                                v-model="formFields.isPayingOtherNote"
                                type="textarea"
                                rows="4">
                              </el-input>
                              <!-- <p class="text-right text-sm mt-0" :class="{ 'text-red-500': notesCurrentCharCount > notesMaxCharCount , 'text-gray-400': notesCurrentCharCount <= notesMaxCharCount}">{{ notesCurrentCharCount }}/{{ notesMaxCharCount }}</p> -->
                           </el-form-item>
                          </div>
                        </div>
                      </transition>
                    </div>
                    </div>
                    <div v-if="windowWidth > 600" class="w-1/2 mt-2">
                      <transition name="slide-fade" mode="out-in">
                        <div>
                          <div v-if="formFields.paymentMethod == 'check'" :key="formFields.paymentMethod" class="pl-2">
                            <p class="font-medium">We will email you instructions to pay by check.</p>
                          </div>
                          <div v-if="formFields.paymentMethod == 'other'" :key="formFields.paymentMethod" class="pl-2">
                            <p class="font-medium">Please tell us about the arrangements you would like to make. <span class="text-red-500">*</span></p>
                            <el-form-item prop="isPayingOtherNote" class="w-full mt-0">
                              <el-input
                              v-model="formFields.isPayingOtherNote"
                              type="textarea"
                              rows="4">
                              </el-input>
                              <!-- <p class="text-right text-sm mt-0" :class="{ 'text-red-500': notesCurrentCharCount > notesMaxCharCount , 'text-gray-400': notesCurrentCharCount <= notesMaxCharCount}">{{ notesCurrentCharCount }}/{{ notesMaxCharCount }}</p> -->
                            </el-form-item>
                          </div>
                        </div>
                      </transition>
                    </div>
                  
                  </div>
                  <transition name="slide-fade" mode="out-in">
                    <div v-if="formFields.paymentMethod == 'credit'" :key="formFields.paymentMethod">
                      <div class="flex py-2">
                        <div class="pl-2 w-full">
                          <p class="font-medium">
                            Select the payment(s) you are making now.
                          </p>
                          <div class="ml-2 w-full">
                            <div class="flex text-sm text-gray-500 w-full">
                                <el-checkbox
                                  v-model="isPayingDeposit"
                                  disabled class="mr-2"
                                ></el-checkbox>
                                <div class="ml-2">
                                <transition name="slide-fade" mode="out-in">
                                  <p v-if="!isSinglePayment" key="deposit" :class="{ muench: isPayingDeposit, nopoint: isPayingOther }">Deposit ({{ depositDisplay }})</p>
                                  <p v-else key="tuition" :class="{ muench: isPayingDeposit, nopoint: isPayingOther }">Tuition ({{ depositDisplay }})</p>
                                </transition>
                                  <p class="font-normal ml-2" :class="{ muench: isPayingDeposit, nopoint: isPayingOther }">
                                    To secure your spot now.
                                  </p>
                                </div>
                              </div>
                          
                              <div v-if=" secondPaymentAmount > 0 && secondPaymentAmount != '' " 
                                class="flex items-start space-x-2" >
                                <el-checkbox 
                                  v-model="isPayingSecondPayment"
                                  :disabled="isPayingOther" class="  mr-2  "
                                >
                                </el-checkbox>
                                <div class="text-sm text-gray-500 cursor-pointer" @click="toggleCheck('secondPayment')" >
                                  <transition name="slide-fade" mode="out-in">
                                  <p :key="secondPaymentDisplay" :class="{ muench: isPayingSecondPayment, nopoint: isPayingOther }"
                                    >Second Payment ({{
                                      secondPaymentDisplay
                                    }})</p
                                  ></transition>
                                  <p class="font-normal pl-2" :class="{ muench: isPayingSecondPayment, nopoint: isPayingOther }">
                                    Due by {{ secondPaymentDueDate }}.
                                  </p>
                                </div>
                              </div>
                              <div v-if=" balanceAmount > 0 && balanceAmount != '' " class="flex items-start space-x-2">
                                  <el-checkbox 
                                  v-model="isPayingBalance"
                                  :disabled="isPayingOther || ( ( secondPaymentAmount > 0 && secondPaymentAmount != '') && !isPayingSecondPayment)"
                                  class="  mr-2  "
                                >
                                </el-checkbox>
                                <div class="text-sm text-gray-500 cursor-pointer w-full" @click="toggleCheck('balance')" :class="{ nopoint: isPayingOther }">
                                  <transition name="slide-fade" mode="out-in">
                                    <p 
                                    :key="balanceDisplay"  
                                    :class="{ muench: isPayingBalance, nopoint: isPayingOther }">
                                    <span v-if=" secondPaymentAmount > 0 && secondPaymentAmount != '' " >Final </span>
                                    Balance ({{ balanceDisplay }})
                                  </p>
                                  </transition>
                                  <p class="font-normal pl-2" :class="{ muench: isPayingBalance, nopoint: isPayingOther }">Due by {{ balanceDueDate }}.</p>
                                </div>
                              </div>
                              <div v-if="SRSType === 'SRS'" class="flex items-start space-x-2 w-full">
                                <el-checkbox v-model="isPayingSingle" :disabled="isPayingOther"></el-checkbox>
                                <div class="text-sm text-gray-500 cursor-pointer w-full" @click="toggleCheck('single')" :class="{ nopoint: isPayingOther }">
                                  <div class="flex w-full">
                                  <p class="w-5/12" :class="{ muench: isPayingSingle, nopoint: isPayingOther }">
                                  Single Room Supplement ({{ singleDisplay }})</p>
                                  <p class="font-normal w-full ml-1" :class="{ muench: isPayingSingle, nopoint: isPayingOther }">
                                    Otherwise you will be assigned a
                                    roommate.
                                  </p>
                                  </div>
                                  <p class="font-normal ml-2" :class="{ muench: isPayingSingle, nopoint: isPayingOther }">
                                    {{ singleOccupancySupplementNote }}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <el-checkbox v-model="isPayingOther">Pay a different amount.
                                </el-checkbox>
                              </div>
                            </div>
                        </div>
                        <transition name="slide-fade">
                          <div
                            v-if="isPayingOther"
                            class="w-1/2 px-2"
                            :key="isPayingOther"
                          >
                            <p class="font-medium mt-2"> Amount to Pay (USD)<span class="text-red-500"> *</span>
                            </p>
                            <el-form-item prop="differentDollar">
                              <el-input
                                v-mask="currencyMask"
                                v-model="formFields.differentDollar"
                                placeholder="$USD"
                              ></el-input>
                            </el-form-item>
                            <p class="font-medium mt-2">Note</p>
                            <el-input
                              v-model="formFields.differentNote"
                              type="textarea"
                              rows="2"
                            ></el-input>
                          </div>
                        </transition>
                      </div>
                                        
                    </div>
                    
                  </transition>
                  <div v-if="nonRefundable">
                    <hr class="border border-dotted my-2" />
                    <div class="mb-6">
                      <p class="text-xl font-medium pl-2 py-2 mb-2 ">Payment Terms</p>
                      <el-form-item 
                        prop="agreeToTerms"
                        class="pl-5"
                        :class="{ invalid: isInvalid.terms }">  
                        <ul class="list-disc">
                          <li class="leading-7">Your non-refundable deposit will hold your space in this workshop.</li>
                          <li class="leading-7" v-if="secondPaymentAmount > 0 && secondPaymentAmount != ''"> The non-refundable second payment will be due on {{secondPaymentDueDate}}. </li>
                          <li class="leading-7" >The non-refundable balance is due no later than {{this.balanceDueDate}}.</li>
                          <li class="leading-7">All payments are non-refundable and this is why we recommend comprehensive travel insurance including trip cancelation.</li>
                          <li class="leading-7">
                            <p class="font-medium ">In order to register you must read and agree to the
                          <a
                            class="linky font-normal"
                            target="_blank"
                            href="http://muenchworkshops.com/terms-conditions"
                            >Terms and Conditions.
                          </a>
                          <span class="text-red-500"> *</span>
                        </p>
                          </li>
                        </ul>
                        <el-checkbox
                          v-model="formFields.agreeToTerms"
                          class=" pl-4"
                          >Yes, I agree to the Terms and
                          Conditions.
                        </el-checkbox>                        
                      </el-form-item>
                      
                    </div>
                  </div>
                  <div v-else>
                    <hr class="border border-dotted my-2" />
                    <div class="mb-6">
                      <p class="text-xl font-medium pl-2 py-2 mb-2 ">Payment Terms</p>
                      <el-form-item 
                        prop="agreeToTerms"
                        class="pl-5"
                        :class="{ invalid: isInvalid.terms }">  
                          <p class="font-medium ">In order to register you must read and agree to the
                          <a
                            class="linky font-normal"
                            target="_blank"
                            href="http://muenchworkshops.com/terms-conditions"
                            >Terms and Conditions
                          </a>
                          <span class="text-red-500"> *</span>
                        </p>
                        <el-checkbox
                          v-model="formFields.agreeToTerms"
                          class=" pl-4"
                          >Yes, I agree to the Terms and
                          Conditions.
                        </el-checkbox >                        
                      </el-form-item>
                      
                    </div>
                  </div>
                  <div class="space-y-2">
                    <div class="flex w-full justify-center py-4">
                      <el-button
                        v-if="formFields.paymentMethod =='credit'"
                        type="primary"
                        @click="openPaymentValidate('formFields')"
                        >Continue</el-button>
                      <el-button
                        v-else
                        type="primary"
                        @click="register('formFields')"
                      >Send</el-button>
                    </div>
                  </div>
                </div>

              </div>

              <!--EXISTING REGISTRATION-->
              
              <div v-else-if="formFields.type == 'pay'">
                <div>
                  <p class="font-medium pl-2"> Email <span class="text-red-500">*</span></p>
                  <el-form-item prop="email">
                    <el-input
                    placeholder="Email"
                    v-model="formFields.email"
                    ></el-input>
                  </el-form-item>
              </div>
                <div class="flex items-end">
                  <p class="font-medium pl-2 mb-3">
                    Address <span class="text-red-500">*</span>
                  </p>
                </div>
                <div class="space-y-1">
                  <el-form-item prop="billingAddr1">
                    <el-input
                      placeholder="Street Address"
                      v-model="formFields.billingAddr1"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="billingAddr2">
                    <el-input
                      placeholder="Address Line 2"
                      v-model="formFields.billingAddr2"
                    ></el-input>
                  </el-form-item>

                  <div class="flex space-x-2 pt-4">
                    <el-form-item prop="billingCity" class="w-1/2">
                      <el-input placeholder="City" v-model="formFields.billingCity">
                      </el-input>
                    </el-form-item>

                    <el-form-item prop="billingState" class="w-1/2">
                      <el-input
                        placeholder="State/Province"
                        v-model="formFields.billingState"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <div class="flex space-x-2 w-full">
                    <el-form-item prop="billingZip" class=" w-1/2">
                      <el-input
                        placeholder="Postal/Zip Code"
                        v-model="formFields.billingZip"
                      ></el-input>
                    </el-form-item>

                    <el-form-item prop="billingCountry" class=" w-1/2">
                    <el-select v-model="formFields.billingCountry" placeholder="Country">
                      <el-option
                        v-for="item in countryList"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    </el-form-item>
                  </div>
                </div>
                

                <div class="flex mt-3 ">
                  <div class="w-full">
                    <p class="font-medium pl-2">Amount to Pay (USD) <span class="text-red-500"> *</span></p>
                    <el-form-item prop="differentDollar">
                      <el-input
                        v-mask="currencyMask"
                        v-model="formFields.differentDollar"
                        placeholder="$USD"
                        class="w-full"
                      ></el-input>
                    </el-form-item>
                    <p class="font-medium pl-2">Note</p>
                    <el-form-item prop="differentNote">
                    <el-input
                      v-model="formFields.differentNote"
                      type="textarea"
                      rows="2" class=" w-full">
                    </el-input>
                    <!-- <p class="text-right text-sm mt-0" :class="{ 'text-red-500': notesCurrentCharCount > notesMaxCharCount , 'text-gray-400': notesCurrentCharCount <= notesMaxCharCount}">{{ notesCurrentCharCount }}/{{ notesMaxCharCount }}</p> -->
                  </el-form-item>
                  </div>                  
                </div>

                <div class="mt-3" v-if="this.formFields.type == 'pay' && this.nonRefundable">
                  <p class="font-light text-sm text-center">All payments for this workshop are non-refundable.</p>
                </div>

                <div class="space-y-2">
                  <div class="flex w-full justify-center my-6">
                      <el-button type="primary" @click="openPaymentValidate('formFields')">Make Payment</el-button>
                  </div>
                </div>
              </div>
              <div  v-else-if="formFields.showError == true">
                <hr class="border border-dotted mt-4" />
                <div class="flex justify-center text-sm text-gray-300 mt-4">
                  <p>An error occurred.</p>
                </div>
              </div>
              
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <!--Error Messages-->
    <div v-else class="flex justify-center ">
      <div class="border pl-4 pr-10 py-20 rounded shadow-md mb-4 w-7/8 md:w-4/5 text-center bg-white z-10 italic font-light whitespace-pre-wrap">
        {{ showErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
// import loginAPI from "@/api/LoginAPI.js";
import sharedAPI from "@/api/SharedAPI.js";
import store from "@/store.js";
import countries from "countries-list";
import final from '@/components/final'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

export default {
  name: "PaymentForm",
  props: ["code"],
  components: {
    'final': final
  },
  data() {
    let countryValidator = () => {
      if(this.formFields.country == '') {
        return true
      } else {
        return false
      }
    };
    let validateDOBYear = (rule, value, callback) => {
        let currYear = new Date().getFullYear()
        if(value >= currYear || value < 1900) {
          callback(new Error('Please enter a valid 4 digit year'))
        } else {
          callback()
        }
      };
      let validateDOBMonth = (rule, value, callback) => {
        if(value > 12 || value < 1) {
          callback(new Error('Please enter a valid 2 digit month'))
        } else {
          callback()
        }
      };
      let validateDOBDay = (rule, value, callback) => {
        if(value > 31 || value < 1) {
          callback(new Error('Please enter a valid 2 digit day'))
        } else {
          callback()
        }
      };
    return {
		currencyMask,
		comingSoon: null,
		comingSoonText: "",
		formInvalid: false,
		isPayingBalance: null,
		isPayingDeposit: null,
		isPayingOther: null,
		isPayingSecondPayment: null,
		isPayingSingle: null,
		formFields: {
			addr1: "",
			addr2: "",
			billingAddr1: "",
			billingAddr2: "",
			agreeToFitness: null,
			agreeToSpecialTerms: null,
			agreeToTerms: null,
			altPhone: "",
			altPhonePrefix: "",
			altPhonePrefixCountry: "",
			cameraKnowledge: "",
			canToggleType: null,
			cell: "",
			cellPrefix: "",
			cellPrefixCountry: "",
			city: "",
			billingCity: "",
			contactFirst: "",
			contactLast: "",
			contactPhone: "",
			contactPhonePrefix: "",
			contactPhonePrefixCountry: "",
			country: "",
			billingIsSame: undefined,
			billingCountry: "",
			dietaryRequirements: "",
			differentDollar: "",
			differentNote: "",
			dobDay: "",
			dobMonth: "",
			dobYear: "",
			email: "",
			fitnessDetail: "",
			fitnessLevel: "",
			fname: "",
			isAltIntl: null,
			isCellIntl: null,
			isContactIntl: null,
			isPreRegistration: null,
			isPayingOtherNote: "",
			soldOutText: "",
			showSpotsLeft: null,
			lname: "",
			medicalRequirements: "",
			noncreditSRS: "",
			otherInfo: "",
			paymentMethod: "",
			state: "",
			subtitle: "",
			billingState: "",
			selectedCabin: "",
			selectedCabinID: "",
			type: "",
			zip: "",
			billingZip: "",
			waitlistNote: '',
			discountAmt: "",
			originalDeposit: "",
			originalSecondPayment: "",
			originalBalance: "",
			originalTotal: "",
			onlineSaleDescription: "",
			onlineSaleRate: "",
			saleWasApplied: false
		},
		amountPaying: "",
		backgroundImage: "",
		balanceAmount: "",
		balanceDueDate: "",
		balanceDisplay: "",
		balancePaymentText: "",
		cabin: [],
		countryList: [],
		countryPhoneList: [],
		countryPhoneListObj: [],
		delayTimer: "",
		depositAmount: "",
		depositDisplay: "",
		hasCabins: false,
		isInvalid: {
			fitnessLevel: null,
			cameraKnowledge: null,
			selectedCabin: null,
			paymentChecks: null,
			paymentMethod: null,
			terms: null,
			specialFitness: null,
			specialTerms: null,
			noncreditSRS: null,
		},
		isSinglePayment: null,
		fitnessLevel: "",
		loading: false,
		loadingEmail: false,
		loadingText: "Building your form...",
		isSoldOut: null,
		inSalePeriod: false,
		hasPreRegistrations: null,
		//notesCurrentCharCount: 0,
		notesMaxCharCount: 500,
		onlineSaleStartDate: null,
		onlineSaleEndDate: null,
		onlineSaleRate: null,
		onlineSaleDescription: null,
		originalDepositAmount: null,
		originalSecondPaymentAmount: null,
		originalBalanceAmount: null,
		originalTotal: null,
		path: null,
		paymentIntent:{
			param: "",
			code: "",
			type: "",
			status:"",
			clientSecret:"",
		},
		// privacyText: "",
		secondPaymentAmount: "",
		secondPaymentDueDate: "",
		secondPaymentText: "",
		showCabin: null,
		showError: false,
		showErrorMessage: "",
		showStripeModal: null,
		showSpotsLeft: null,
		showRegistrationModal: null,
		SRSType: "",
		singleOccupancySupplement: "",
		singleOccupancySupplementNote: "",
		specialFitness: "",
		nonRefundable: "",
		specialTerms: "",
		spotsLeftLabel: "",
		stripe: {},
		stripeSession: {},
		subtitle: "",
		title: "",
		token: "",
		tokenTime: "",
		total: "",
		totalDisplay: "",
		tripDate: "",
		userList: [],
		waitlistMakePayment: null,
		windowWidth: window.innerWidth,
		workshopCountry: "",
		workshopState: "",
		workshopRegion: "",
		rules: {
			type: [{required: true, message: "Payment type is required", trigger: "blur" }],
			fname: [{required: true, message: "First name is required", trigger: "blur",}],
			lname: [{ required: true, message: "Last name is required", trigger: "blur" }],
			addr1: [{ required: true, message: "Address is required", trigger: "blur" },],
			addr2: [{ required: false }],
			city: [{ required: true, message: "City is required", trigger: "blur" },],
			billingAddr1: [{ required: true, message: "Address is required", trigger: "blur" }],
			billingAddr2: [{ required: false }],
			city: [{ required: true, message: "City is required", trigger: "blur" },],
			state: [{ required: true, message: "State/Province is required", trigger: "blur",}],
			zip: [{ required: true, message: "Postal/Zip code is required", trigger: "blur"},],
			country: [{ required: countryValidator, message: "Country  is required", trigger: "change" }],
			billingCity: [{ required: true, message: "City is required", trigger: "blur" }],
			billingState: [{ required: true, message: "State/Province is required", trigger: "blur"}],
			billingZip: [{ required: true, message: "Postal/Zip code is required", trigger: "blur"},],
			billingCountry: [{ required: true, message: "Country  is required", trigger: "change" }],
			email: [{ required: true, message: "Email is required", trigger: "blur" }],
			cell: [{ required: true, message: "A contact number is required", trigger: "blur"},],
			dobDay: [{ required: true, message: "Enter a day", trigger: "blur" },{validator: validateDOBDay, trigger: "blur"}],
			dobMonth: [{ required: true, message: "Enter a month", trigger: "blur" },{validator: validateDOBMonth, trigger: "blur"}],
			dobYear: [{ required: true, message: "Enter a year", trigger: "blur", }, {validator: validateDOBYear, trigger: "blur"}],
			contactFirst: [{ required: true, message: "Contact's first name is required", trigger: "blur"}],
			contactLast: [{ required: true, message: "Contact's last name is required", trigger: "blur"}],
			contactPhone: [{ required: true, message: "Contact's phone number is required", trigger: "blur"}],
			fitnessLevel: [{ required: true, message: "Response is required", trigger: "change" }],
			fitnessDetail: [{ required: true, message: "Response is required", trigger: "blur" }],
			dietaryRequirements: [{ required: true, message: "Response is required", trigger: "blur" }],
			medicalRequirements: [{ required: true, message: "Response is required", trigger: "blur" }],
			isPayingOtherNote: [{ required: true, message: "Response is required", trigger: "blur" }],
			selectedCabin: [{ required: true, message: "Select a cabin", trigger: "blur" },],
			differentDollar: [{ required: true, message: "Enter an amount", trigger: "blur" },{ message: "Amount must meet the deposit requirement", trigger: "blur",}],
			cameraKnowledge: [{ required: true, message: "Response is required", trigger: "change", }],
			noncreditSRS: [{ required: true, message: "Response is required", trigger: "change", }],
			paymentMethod: [{ required: true, message: "Select a payment method", trigger: "blur", }],
			agreeToFitness: [{ required: true, message: "Please acknowledge fitness requirements before continuing", trigger: "change", }],
			agreeToTerms: [{ required: true, message: "You must agree to the terms to continue", trigger: "change", }],
			agreeToSpecialTerms: [{ required: true, message: "You must agree to the special terms to continue", trigger: "change", },]
		},
	};
	},
	mounted() {
		this.loadData();
	},
	computed: {
	amountPayingDisplay: function() {
		return this.toCurrency(this.amountPaying);      
	},
	paymentModalTitle: function() {
		return "Payment: " + this.amountPayingDisplay;
	},
	cabinQty() {
		return this.cabin.length;
	},
	depositDisplay: function() {
		return this.toCurrency(this.depositAmount);
	},
	secondPaymentDisplay: function() {
		return this.toCurrency(this.secondPaymentAmount);
	},
	totalDisplay: function() {
		return this.toCurrency(this.total);
	},
	otherDisplay: function() {
		return this.toCurrency(this.formFields.differentDollar.replace(/[&\/\\,$]/g, ''));
	},
	singleDisplay: function() {
		return this.toCurrency(this.singleOccupancySupplement);
	},
	balanceDisplay: function() {
		return this.toCurrency(this.balanceAmount);
	},
	fitnessDescription: function() {
		var fit = this.formFields.fitnessLevel;
		if (fit == "easy") {
		return '"I can walk to the corner."';
		} else if (fit == "moderate") {
		return '"I can walk to the store."';
		} else if (fit == "difficult") {
		return '"I can walk up a mountain."';
		} else if (fit == "challenging") {
		return '"I can run away if a group of highwaymen appears."';
		
		}
	},
	balancePaymentText: function() {
		if (this.secondPaymentAmount != "" && this.secondPaymentAmount != 0) {
		return (
			"The balance of " +
			this.balanceDisplay +
			" is due by " +
			this.balanceDueDate +
			".\n"
		);
		}
	},
	secondPaymentText: function() {
		if (this.secondPaymentAmount != "" && this.secondPaymentAmount != 0) {
		return (
			"A second payment of " +
			this.secondPaymentDisplay +
			" is due on " +
			this.secondPaymentDueDate +
			".\n"
		);
		}
	},
	itemsDisplay: function() {
		var deposit = "",
		balance = "",
		second = "",
		single = "";
		if(this.isSinglePayment) {
		deposit = "Tuition (" + this.depositDisplay + ")";
		} else {
		if (this.isPayingDeposit) {
		deposit = "Deposit (" + this.depositDisplay + ")";
		}
		if (this.isPayingSecondPayment) {
		second = "Second Payment (" + this.secondPaymentDisplay + ")";
		}
		if (this.isPayingBalance) {
		balance = "Balance (" + this.balanceDisplay + ")";
		}
		if (this.isPayingSingle) {
		single = "Single Room Supplement (" + this.singleDisplay + ")";
		}
		}
		var disp = [deposit, second, balance, single].filter(Boolean).join("\n");
		if (disp == "") {
		disp = "Nothing Selected";
		}
		if (this.isPayingOther) {
		disp = "Other Amount (" + this.otherDisplay + ")";
		}
		return disp;
	},
	paymentMethodNote() {
		if (this.formFields.paymentMethod == "check") {
		return "You will receive payment instructions by email.";
		} else if (this.formFields.paymentMethod == "credit") {
		return "Will be charged to your credit card.";
		} else if (this.formFields.paymentMethod == "other") {
		return "Please note the special arrangements below.";
		}
	},
	missingRequiredFields() {
		if(this.formFields.type == 'register' && (this.formFields.fname == '' || this.formFields.lname == '' || this.formFields.email == '' || this.formFields.addr1 == '' || this.formFields.city == '' ||
		this.formFields.state == '' || this.formFields.zip == '' || this.formFields.country == '' || this.formFields.cell == '' || this.formFields.altPhone == '' || this.formFields.dobDay == '' || this.formFields.dobMonth == '' ||
		this.formFields.dobYear == '' || this.formFields.contactFirst == '' || this.formFields.contactPhone == '' || this.formFields.fitnessLevel == '' || (this.formFields.specialFitness && this.formFields.agreeToFitness == null) ||
		this.formFields.fitnessDescription == '' || this.formFields.dietaryRequirements == '' || this.formFields.medicalRequirements == '' || this.formFields.otherInfo == '' || 
		this.formFields.cameraKnowledge == '' || this.formFields.paymentMethod == '' || this.formFields.agreeToSpecialTerms == null || (this.SRSType === 'SRS' && this.formFields.noncreditSRS == '') ||
		(this.isPayingOther && this.formFields.differentDollar == '') || (this.formFields.paymentMethod == 'other' && this.formFields.isPayingOtherNote == '')
		)) {
		return true
		}
		else if(this.formFields.type == 'pay' && (this.formFields.differentDollar  === "" || this.formFields.fname == '' || this.formFields.lname == '' || this.formFields.email == '' || this.formFields.billingAddr1 == '' || this.formFields.billingCity == '' ||
		this.formFields.billingState == '' || this.formFields.billingZip == '' || this.formFields.billingCountry == '' )) {
		return true
		}
		else if(this.formFields.type == '' && (this.formFields.fname == '' || this.formFields.lname == '' || this.formFields.email == '' || this.formFields.cell == '')) {
		return true
		}
	},
	// notesCurrentCharCount(){
	// if(this.formFields.type == 'register'){
	//   return (this.formFields.isPayingOtherNote ? this.formFields.isPayingOtherNote.length : 0)
	// }
	// else if(this.formFields.type == 'pay'){
	//   return (this.formFields.differentNote ? this.formFields.differentNote.length : 0)
	// }
	// else{
	//   return 0
	// }
		
	// }
	},
	watch: {
	"formFields.dobDay"() {
		if(this.formFields.dobDay.length == 2) {
		this.$refs.dobYear.focus()
		}
	},
	"formFields.dobMonth"() {
		if(this.formFields.dobMonth.length == 2) {
		this.$refs.dobDay.focus()
		}
	},
	"formFields.email": {
		handler: "checkEmail",
	},
	"isPayingDeposit": {
		handler: "handleAmountUpdate",
	},
	"isPayingBalance": {
		handler: "handleAmountUpdate",
	},
	"isPayingSecondPayment": {
		handler: "handleAmountUpdate",
	},
	"isPayingSingle": {
		handler: "handleAmountUpdate",
	},
	"isPayingOther"(val) {
		this.isInvalid.paymentChecks = false
		if (val == false) {
		this.formFields.differentDollar = "";
		if (this.formFields.paymentMethod == "credit" && (this.formFields.type == "register")) {
			this.isPayingDeposit = true;
		}
		} else {
		this.isPayingDeposit = false;
		this.isPayingSingle = false;
		this.isPayingSecondPayment = false;
		this.isPayingBalance = false;
		this.amountPaying = this.formFields.differentDollar.replace(/[&\/\\,$]/g, '');
		}
	},
	"formFields.differentDollar"() {
		if (this.formFields.differentDollar != "") {
		this.amountPaying = this.formFields.differentDollar.replace(/[&\/\\,$]/g, '')
		}
	},
	"formFields.agreeToTerms"() {
		if (this.formFields.agreeToTerms == false) {
		this.formFields.agreeToTerms = undefined;
		}
		else{
		this.isInvalid.terms = false
		this.formFields.agreeToSpecialTerms = this.formFields.agreeToTerms
		} 
	},
	"formFields.agreeToSpecialTerms"() {
		if (this.formFields.agreeToSpecialTerms == false) {
		this.formFields.agreeToSpecialTerms = undefined;
		}
		else{
		this.isInvalid.specialTerms = false
		}
	},
	"formFields.noncreditSRS"() {
		if (this.formFields.noncreditSRS != "") {
		this.isInvalid.noncreditSRS = false
		}
	},
	"formFields.fitnessLevel"() {
		if (this.formFields.fitnessLevel != "") {
		this.isInvalid.fitnessLevel = false
		}
	},
	"formFields.addr1"() {
		if (this.formFields.paymentMethod == "credit") {
		this.formFields.billingAddr1 = this.formFields.addr1;
		}
	},
	"formFields.addr2"() {
		if (this.formFields.paymentMethod == "credit") {
		this.formFields.billingAddr2 = this.formFields.addr2;
		}
	},
	"formFields.city"() {
		if (this.formFields.paymentMethod == "credit") {
		this.formFields.billingCity = this.formFields.city;
		}
	},
	"formFields.state"() {
		if (this.formFields.paymentMethod == "credit") {
		this.formFields.billingState = this.formFields.state;
		}
	},
	"formFields.country"() {
		if (this.formFields.country.toLowerCase() === "usa") {
		this.formFields.country = "USA"
		} else if ( this.formFields.country == '') {
		this.rules.country[0].required = true
		} else {
		this.rules.country[0].required = false
		}
		if (this.formFields.paymentMethod == "credit") {
		this.formFields.billingCountry = this.formFields.country;
		}
	},
	"formFields.billingCountry"() {
		if (this.formFields.billingCountry.toLowerCase() === "usa") {
		this.formFields.billingCountry = "USA"
		}
	},
	"formFields.zip"() {
		if (this.formFields.paymentMethod == "credit") {
		this.formFields.billingZip = this.formFields.zip;
		}
	},
	"formFields.paymentMethod"() {
		this.isPayingDeposit = false;
		this.isPayingBalance = false;
		this.isPayingSingle = false;
		this.isPayingSecondPayment = false;
		if (this.formFields.type == "register"){
		this.isPayingOther = false;
		}
		if( this.formFields.paymentMethod == "credit") {
		if (this.formFields.type == "register"){
			this.isPayingDeposit = true;
			this.formFields.billingIsSame = true;
		}
		}
		this.handleAmountUpdate();
		if (this.formFields.paymentMethod != "") {
		this.isInvalid.paymentMethod = false
		}
	},
	"formFields.billingIsSame"(val) {
		if (val) {
		this.formFields.billingAddr1 = this.formFields.addr1 ;
		this.formFields.billingAddr2 = this.formFields.addr2;
		this.formFields.billingCity = this.formFields.city;
		this.formFields.billingState = this.formFields.state;
		this.formFields.billingZip= this.formFields.zip;
		this.formFields.billingCountry = this.formFields.country;
		} else{
		this.formFields.billingAddr1 = "";
		this.formFields.billingAddr2 =  "";
		this.formFields.billingCity =  "";
		this.formFields.billingState=  "";
		this.formFields.billingZip=  "";
		this.formFields.billingCountry =  "";
		}
	},
	"formFields.selectedCabin"(cabin) {
		this.depositAmount = this.cabin[cabin].depositAmount;
		this.secondPaymentAmount = this.cabin[cabin].secondPaymentAmount;
		this.balanceAmount = this.cabin[cabin].balanceAmount;
		this.total = this.cabin[cabin].total;
		this.originalDepositAmount = this.cabin[cabin].depositAmount;
		this.originalSecondPaymentAmount = this.cabin[cabin].secondPaymentAmount;
		this.originalBalanceAmount = this.cabin[cabin].balanceAmount;
		this.originalTotal = this.cabin[cabin].total;
		this.formFields.selectedCabinID = this.cabin[cabin].cabinID;
		this.updateDisplayValues(false);
		this.handleAmountUpdate();
	},
	token: {
		handler: "getWorkshop",
	},
	"formFields.type" (){
		if (this.formFields.type == "pay"){
		this.formFields.paymentMethod = 'credit'
		this.isPayingDeposit = null
		this.isPayingOther = true
		} else{
		this.formFields.paymentMethod = ""
		if(!this.isSinglePayment && this.hasCabins && !this.comingSoon && !this.isSoldOut) {
			this.setSelectedRow()
		}
		}
		if(this.formFields.type == "register" && this.hasPreRegistrations) {
		this.formFields.isPreRegistration = true
		} else {
		this.formFields.isPreRegistration = null
		}
	},
	"formFields.cellPrefixCountry"(){
		this.formFields.cellPrefix = this.formFields.cellPrefixCountry.replace(/[^0-9]/g, '')
	},
	"formFields.altPhonePrefixCountry"(){
		this.formFields.altPhonePrefix = this.formFields.altPhonePrefixCountry.replace(/[^0-9]/g, '')
	},
	"formFields.contactPhonePrefixCountry"(){
		this.formFields.contactPhonePrefix = this.formFields.contactPhonePrefixCountry.replace(/[^0-9]/g, '')
	},
	// "waitlistMakePayment"() {
	//   if(this.waitlistMakePayment == true) {
	//     this.formFields.type = "register"
	//     // this.isPayingOther = true
	//     // this.formFields.paymentMethod = "credit"
	//   } else {
	//     this.formFields.type = ""       
	//     this.isPayingOther = false
	//     this.formFields.paymentMethod = ""
	//   }

	// }
	},

	methods: {
	closePayment(payload){
		this.showStripeModal = false
		alert(payload.error);
	},

	//Cabin table methods
	setSelectedRow() {
		setTimeout(() => this.setCurrent(this.cabin[0]), 500)
	},
	setCurrent(row) {
		this.$refs.singleTable.setCurrentRow(row);

	},
	handleCurrentChange(val) {
		this.currentRow = val;
		this.formFields.selectedCabin = val.index
		//updateDisplayValues(false);
	},
	/**
	 * Loads list of staffs for select using GraphQL
	 */
	async loadData() {
		// this.formFields.paymentMethod = "credit"
		// this.showStripeModal = true

		if (this.code === undefined) {
		this.showError = true;
		this.showErrorMessage = "Hmm, nothing to show here.\n\nTry again or contact us!";
		return;
		}
		var DOM = this;
		DOM.formFields.type = '';
		document.title = "Muench Workshops";
		DOM.stripe = require('stripe')('pk_live_bR2dqISTvS3FKsFvmhgc5R0C');
	//    DOM.stripe = require('stripe')('pk_test_51J7KxzHM1ClCLQWIfEXO8k32CrSJ74umlJx2MBOGkh4yVTMqSuq1s1PmHvhtRwMCAUT4UWx76pdm569bRDthC9i900ZPjuvqB2');

		this.makeCountryLists(DOM);
		this.getWorkshop();
		try {
			this.loading = true;
			this.formFields.canToggleType = true;
		} 
		catch (e) {
			console.log(e);
			let params = {
				error: e
			}
			sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
			//this.displayUserError(e);
		} 
	},

	async getWorkshop() {
		var DOM = this;
		var data = JSON.stringify({
		query: [
			{
			FM_WS_F: this.code,
			},
		]
		});
		try{
		DOM.loading = true;
		var response = await sharedAPI.getWorkshop(data);
			//console.log(response)
		if( response.data.response.data.error  || !(response.data.response.data[0]) ){
			DOM.showError = true
			DOM.showErrorMessage = "There was a problem getting the details for this workshop. Please try again or contact us for assistance."
			if(response.data.response.data.error){
			console.log(response.data.response.data.error)
			}
			throw "No workshop found."
		}

		var data = response.data.response.data[0].fieldData;
		var portal = response.data.response.data[0].portalData.pricing;

		//set bg
		if (
		data["workshop__workshopImage__IMAGE__imageID::webAddress"] != ""
			) {
			DOM.backgroundImage =
			data["workshop__workshopImage__IMAGE__imageID::webAddress"];
		}
		if (data["WorkshopStatus"] != "Active" && data["WorkshopStatus"] != "Launching"){
			window.location.href ="https://www.muenchworkshops.com"
		}
		DOM.title = data["zctIntroTitle"];
		if (data["introSubtitle"] != "") {
			DOM.subtitle = data["introSubtitle"];
			DOM.formFields.subtitle = data["introSubtitle"];
		}
		DOM.singleOccupancySupplement = data["SRS"];
		DOM.isSinglePayment = data["zcnIsSinglePayment"] == "1";
		DOM.SRSType = data["SRSType"];
		var seats = data["zcnSeatsAvailable"]
		var threshold = data["spotsLeftThreshold"]
		DOM.showSpotsLeft = seats <= threshold ? true:false;
		DOM.isSoldOut = seats < 1 ? true:false;
		DOM.comingSoon = data["webComingSoon"] == 1 ? true : false;
		DOM.soldOutText = DOM.isSoldOut ? data["workshops__PREFERENCES__cart::paymentFormWaitlistSnippet"].replaceAll('\r', '\n') : "";
		DOM.comingSoonText = DOM.comingSoon ? data["workshops__PREFERENCES__cart::paymentFormComingSoonSnippet"].replaceAll('\r', '\n') : "";
		DOM.spotsLeftLabel = data["zctSeatsAvailableSpotsLeftLabel"].toUpperCase();
		DOM.balanceDueDate = data["DueDateBalancePayment"];
		DOM.secondPaymentDueDate = data["DueDate2ndPayment"];
		DOM.singleOccupancySupplementNote = data["SRS Note"];
		// DOM.privacyText =
		//   data["workshops__PREFERENCES__cart::paymentFormPrivacyText"].replaceAll('\r', '\n');
		// if (DOM.singleOccupancySupplementNote.charAt(-1 != ".")) {
		//   DOM.singleOccupancySupplementNote =
		//     DOM.singleOccupancySupplementNote + ".";
		// }
		DOM.specialTerms = data["TermsSpecial"].replaceAll('\r', '\n');
		DOM.nonRefundable = data["NonRefundable"].replaceAll('\r', '\n');

		//check fitness level
		DOM.fitnessLevel = data["Fitness Level"].toLowerCase()
		if (DOM.fitnessLevel  == "difficult" || DOM.fitnessLevel  == "challenging") {
			DOM.specialFitness = data["Fitness Level Description"].replaceAll('\r', '\n');
		}

		//set travel dates
		DOM.setDates(data);

		//set page title
		document.title = DOM.title + ", " + DOM.tripDate;
		DOM.formFields.id = data["__WorkshopsID_pk"];

		DOM.onlineSaleStartDate = data["workshops__PREFERENCES__cart::onlineSaleStartDate"];
		DOM.onlineSaleEndDate = data["workshops__PREFERENCES__cart::onlineSaleEndDate"];
		DOM.onlineSaleRate = Math.abs(data["workshops__PREFERENCES__cart::onlineSaleRate"]);
		DOM.onlineSaleDescription = data["workshops__PREFERENCES__cart::onlineSaleDescription"];

		//set if in sales price period
		try{
			let today = new Date();
			let currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			let startDate = new Date(DOM.onlineSaleStartDate);
			let endDate = new Date(DOM.onlineSaleEndDate);

			if((startDate == null) || (endDate == null)){
			DOM.inSalePeriod = false;
			}
			else if(data["WorkshopStatus"] != "Active" && data["WorkshopStatus"] != "Launching"){
			DOM.inSalePeriod = false;
			}
			else if(DOM.isSoldOut){
			DOM.inSalePeriod = false;
			}
			else if((startDate <= currentDate) && (endDate >= currentDate)){
			DOM.inSalePeriod = true;
			}
			else{
			DOM.inSalePeriod = false;
			}
		}
		catch (e){
			console.log(e)
		}

		//set price fields
		if (data["CabinPricingToggle"] == "Cabin Pricing") {
			DOM.showCabin = true;
			DOM.hasCabins = true;
		}
		var len = portal.length;
		for (var i = 0; i < len; i++) {
			DOM.cabin[i] = {
			index: i,
			inventory: portal[i]["Workshops_WORKSHOPSPRICING::Inventory"],
			isAvailable: portal[i]["Workshops_WORKSHOPSPRICING::Inventory"] > 0 ? 1:0,
			total: portal[i]["Workshops_WORKSHOPSPRICING::PricingAmount"],
			depositAmount: portal[i]["Workshops_WORKSHOPSPRICING::PricingDeposit"],
			secondPaymentAmount: portal[i]["Workshops_WORKSHOPSPRICING::Pricing2ndPayment"] != "" && portal[i]["Workshops_WORKSHOPSPRICING::Pricing2ndPayment"] != 0 ? portal[i]["Workshops_WORKSHOPSPRICING::Pricing2ndPayment"] : "",
			balanceAmount: portal[i]["Workshops_WORKSHOPSPRICING::PricingBalancePayment"], 
			desc: portal[i]["Workshops_WORKSHOPSPRICING::PricingDescription"], 
			cabinID: portal[i]["Workshops_WORKSHOPSPRICING::__WorkshopsPricingID_pk"], 
			};
			if ((!(DOM.formFields.selectedCabin) && !(DOM.formFields.selectedCabin === 0) && (DOM.cabin[i].inventory > 0)) || data["CabinPricingToggle"] != "Cabin Pricing" ) {
			DOM.formFields.selectedCabin =  i
			} 
			// else if(DOM.isSoldOut) {
			//   DOM.formFields.selectedCabin =  i
			// }
			if (data["WorkshopStatus"] != "Active" && data["WorkshopStatus"] != "Launching"){
			window.location.href ="https://www.muenchworkshops.com"
			}
			DOM.title = data["zctIntroTitle"];
			DOM.workshopCountry = data["WorkshopCountry"];
			DOM.workshopState = data["WorkshopState"];
			DOM.workshopRegion = data["workshop__COUNTRY__countryName::region"];

			//Google Analytics
			if (DOM.$gtm.enabled()) {
			dataLayer.push({
				event: 'registrationFormLoaded'
			});
			}           

			if (data["introSubtitle"] != "") {
			DOM.subtitle = data["introSubtitle"];
			DOM.formFields.subtitle = data["introSubtitle"];
			}
			DOM.singleOccupancySupplement = data["SRS"];
			DOM.isSinglePayment = data["zcnIsSinglePayment"] == "1";
			DOM.SRSType = data["SRSType"];
			var seats = data["zcnSeatsAvailable"]
			var threshold = data["spotsLeftThreshold"]
			DOM.showSpotsLeft = seats <= threshold ? true:false;
			DOM.isSoldOut = seats < 1 ? true:false;
			DOM.comingSoon = data["webComingSoon"] == 1 ? true : false;
			DOM.hasPreRegistrations = data["zcnHasPreRegistrations"] == 1 ? true : false;
			DOM.soldOutText = DOM.isSoldOut ? data["workshops__PREFERENCES__cart::paymentFormWaitlistSnippet"].replaceAll('\r', '\n') : "";
			DOM.comingSoonText = DOM.comingSoon ? data["workshops__PREFERENCES__cart::paymentFormComingSoonSnippet"].replaceAll('\r', '\n') : "";
			DOM.spotsLeftLabel = data["zctSeatsAvailableSpotsLeftLabel"].toUpperCase();
			DOM.balanceDueDate = data["DueDateBalancePayment"];
			DOM.secondPaymentDueDate = data["DueDate2ndPayment"];
			DOM.singleOccupancySupplementNote = data["SRS Note"];
			DOM.specialTerms = data["TermsSpecial"].replaceAll('\r', '\n');
			DOM.nonRefundable = data["NonRefundable"].replaceAll('\r', '\n');

			//check fitness level
			DOM.fitnessLevel = data["Fitness Level"].toLowerCase()
			if (DOM.fitnessLevel  == "difficult" || DOM.fitnessLevel  == "challenging") {
			DOM.specialFitness = data["Fitness Level Description"].replaceAll('\r', '\n');
			}

			//set travel dates
			DOM.setDates(data);

			//set page title
			document.title = DOM.title + ", " + DOM.tripDate;
			DOM.formFields.id = data["__WorkshopsID_pk"];

			//set price fields
			if (data["CabinPricingToggle"] == "Cabin Pricing") {
			DOM.showCabin = true;
			DOM.hasCabins = true;
			}
			var len = portal.length;


		}
		if(DOM.formFields.selectedCabin || DOM.formFields.selectedCabin === 0)
		{
			DOM.depositAmount = DOM.cabin[DOM.formFields.selectedCabin].depositAmount;
			DOM.secondPaymentAmount = DOM.cabin[DOM.formFields.selectedCabin].secondPaymentAmount;
			DOM.balanceAmount = DOM.cabin[DOM.formFields.selectedCabin].balanceAmount;
			DOM.total = DOM.cabin[DOM.formFields.selectedCabin].total;      

			DOM.originalDepositAmount = DOM.cabin[DOM.formFields.selectedCabin].depositAmount;
			DOM.originalSecondPaymentAmount = DOM.cabin[DOM.formFields.selectedCabin].secondPaymentAmount;
			DOM.originalBalanceAmount = DOM.cabin[DOM.formFields.selectedCabin].balanceAmount;
			DOM.originalTotal = DOM.cabin[DOM.formFields.selectedCabin].total;

			//if we are in a sale
			if(DOM.inSalePeriod){
			//Never let total go below 0
			if (DOM.total - DOM.onlineSaleRate < 0){
				DOM.total = 0;
				DOM.depositAmount = 0;
				DOM.secondPaymentAmount = 0;
				DOM.balanceAmount = 0;
			}
			//If there is no balance amount
			else if ( DOM.balanceAmount <= 0 || DOM.balanceAmount == '' || DOM.isSinglePayment){
				DOM.depositAmount = DOM.depositAmount - DOM.onlineSaleRate;
			}
			//If there is a balance amount but the sale is greater
			else if( DOM.balanceAmount - DOM.onlineSaleRate <= 0){
				DOM.balanceAmount = 0;
				let balanceDifference = DOM.onlineSaleRate - DOM.balanceAmount;
				//If the sale isnt greater than the second payment
				if(DOM.secondPaymentAmount != 0 && DOM.secondPaymentAmount != null && DOM.secondPaymentAmount != undefined && (DOM.secondPaymentAmount - balanceDifference > 0)){
				DOM.secondPaymentAmount = DOM.secondPaymentAmount - balanceDifference;
				}
				//if the sale is greater than the second payment too
				else{
				let secondPaymentDifference = balanceDifference - DOM.secondPaymentAmount;
				DOM.secondPaymentAmount = 0;
				DOM.depositAmount = DOM.depositAmount - secondPaymentDifference
				}
			}
			else{
				DOM.balanceAmount = DOM.originalBalanceAmount - DOM.onlineSaleRate;
			}
			}
			if ( (DOM.balanceAmount > 0 && DOM.balanceAmount != '') || DOM.isSinglePayment){
			DOM.type = 'register';
			}
		}

		DOM.loading = false;
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		}
		catch(e) {
		console.log(e);
		var message = await this.displayUserError(e)
		DOM.$message.error(message)
		DOM.loading = false;
		let params = {
			error: e,
			workshopDetails: this.formFields
		}
		sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
		};
	},

	toCurrency(amt) {
		var formatCurrency = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		});
		var amount = formatCurrency.format(amt); 
		var splitAmount = amount.split(".")[0];
		return splitAmount;
	},
	handleAmountUpdate() {
		this.isInvalid.paymentChecks = false
		var DOM = this;
		var deposit = 0,
		balance = 0,
		second = 0,
		single = 0,
		other = 0;
		if (DOM.isPayingDeposit) {
		deposit = DOM.depositAmount;
		}
		if (DOM.isPayingBalance) {
		balance = DOM.balanceAmount;
		}
		if (DOM.isPayingSecondPayment) {
		second = DOM.secondPaymentAmount;
		}
		if (DOM.isPayingSingle) {
		single = DOM.singleOccupancySupplement;
		}
		var amt = deposit + balance + single + other + second;
		this.amountPaying = amt;
	},    
	validEmail: function(email) {
		//var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		//var re = new RegExp("[a-zA-Z0-9!#$%&'*\+-/=?^_`{|}~\B.\B]+@[]+.[]+");
		var isValid;
		var re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		isValid = re.test(email);
		//console.log(email);
		//console.log (isValid);
		return (isValid);
	},
	validPhone: function(phone){
		// if (phone.length > 14){
		//   return false;
		// }
		return true
		//let re = new RegExp("\+?\d?\d?\-?\d?\d?\d?\d?(?\d\d\d\)?-?\d\d\d-?\d\d\d\d");
		//return re.test(phone);
	},
	makeCountryLists(DOM) {
		//build country list
		var data = countries.countries;
		for (var i in data) {
		var key = i;
		var val = data[key];
		var label = val.name;
		var value = val.name;
			if(value != "United States") {
			DOM.countryList.push({ value: value, label: label, link: label });
			}
		}

		DOM.countryList.sort();
		// sort by name
		DOM.countryList.sort(function(a, b) {
		var nameA = a.label.toUpperCase(); // ignore upper and lowercase
		var nameB = b.label.toUpperCase(); // ignore upper and lowercase
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
		});

		// add US to top 
		DOM.countryList.unshift({ value: "USA", label: "USA", link: "USA" });

		//build phone list
		for (var i in data) {
		var key = i;
		var val = data[key];
		if (val.phone.indexOf(",") != -1) {
			//comma found
			//find all commas
			//push a value for the beginning
			var indices = [];
			indices.push(0);
			for (var j = 0; j < val.phone.length; j++) {
			if (val.phone[j] === ",") {
				indices.push(j);
			}
			}
			indices.push(val.phone.length);
			var commas = Object.keys(indices).length;
			//push a prefix for each occurence
			for (var j = 0; j < commas - 1; j++) {
			if (j == 0) {
				var phone = val.phone.substring(indices[j], indices[j + 1]);
			} else {
				var phone = val.phone.substring(indices[j] + 1, indices[j + 1]);
			}
			var label = val.emoji + "    " + val.name + " +" + phone;
			var value = phone;
			DOM.countryPhoneList.push({
				value: label,
				label: label,
				name: val.name,
			});
			}
		} else {
			//no comma
			var label = val.emoji + "    " + val.name + " +" + val.phone;
			var value = val.phone;
			DOM.countryPhoneList.push({
			value: label,
			label: label,
			name: val.name,
			});
		}
		DOM.countryPhoneList.sort();
		// sort by name
		DOM.countryPhoneList.sort(function(a, b) {
			var nameA = a.name.toUpperCase(); // ignore upper and lowercase
			var nameB = b.name.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
			return -1;
			}
			if (nameA > nameB) {
			return 1;
			}
			return 0;
		});
		}
	},
	async openPaymentValidate(formFields){
		this.isInvalid.paymentChecks = false
		var DOM = this;
		DOM.loadingText = "Verifying..."
		var pageData = this.formFields;
		pageData.title = this.title;
		pageData.token = this.token;
		pageData.isSoldOut = this.isSoldOut;
		pageData.comingSoon = this.comingSoon;
		pageData.form = this.code;
		pageData.tripDate = this.tripDate;
		pageData.cabinDesc = this.showCabin ? this.cabin[pageData.selectedCabin].desc : null;
		pageData.depositAmount = this.cabin[pageData.selectedCabin].depositAmount;
		pageData.secondPaymentAmount = this.cabin[pageData.selectedCabin].secondPaymentAmount;
		pageData.balanceAmount = this.cabin[pageData.selectedCabin].balanceAmount;
		pageData.singleOccupancySupplement = this.singleOccupancySupplement;
		pageData.specialTerms = this.specialTerms;
		pageData.total = this.cabin[pageData.selectedCabin].total;
		pageData.amount = this.amountPaying;
		//pageData.email = this.email;
		//pageData.phone = this.phone;

		let isValidEmail = await this.validEmail(pageData.email);
		if(!isValidEmail){
		DOM.formInvalid = true;
			this.$message({
			message: "Email Address is Invalid.",
			type: 'error',
			duration: 6000
			});
			return false
		};

		await DOM.$refs[formFields].validate((valid) => {
		if ((pageData.type == 'pay' || pageData.paymentMethod == 'credit') && (pageData.amount < 1 || !pageData.amount )){
			DOM.formInvalid = true;
			this.$message({
			message: "The amount being paid must be above $1 USD.",
			type: 'error',
			duration: 6000
			});
			return false
		}
		if (!valid) {
			DOM.formInvalid = true;
			this.$message({
			message: "Please fill in all required fields.",
			type: 'error',
			duration: 6000
			});
			return false
		}
		if(valid){
			this.openPayment()
		}
		return
		});

	},

	async openPayment(){
		this.isInvalid.paymentChecks = false
		this.loading = true
		var DOM = this;
		DOM.loadingText = "Verifying..."
		var pageData = this.formFields;
		pageData.title = this.title;
		pageData.token = this.token;
		pageData.isSoldOut = this.isSoldOut;
		pageData.comingSoon = this.comingSoon;
		pageData.form = this.code;
		pageData.tripDate = this.tripDate;
		pageData.cabinDesc = this.showCabin ? this.cabin[pageData.selectedCabin].desc : null;
		// pageData.depositAmount = this.cabin[pageData.selectedCabin].depositAmount;
		// pageData.secondPaymentAmount = this.cabin[pageData.selectedCabin].secondPaymentAmount;
		// pageData.balanceAmount = this.cabin[pageData.selectedCabin].balanceAmount;
		// pageData.total = this.cabin[pageData.selectedCabin].total;
		//Replacing because it screws up sales
		pageData.depositAmount = this.depositAmount;
		pageData.secondPaymentAmount = this.secondPaymentAmount;
		pageData.balanceAmount = this.balanceAmount;
		pageData.total = this.total;

		pageData.singleOccupancySupplement = this.singleOccupancySupplement;
		pageData.specialTerms = this.specialTerms;
		pageData.amount = this.amountPaying;

		try{
		var query= [{ 
			FM_WS_F: this.formFields.form, 
			token: this.formFields.token 
		}]
		if(this.formFields.genericPayment) {
			var metadata = {
			Name: this.formFields.fname + " " + this.formFields.lname,
			GenericPayment: true,
			Workshop: this.formFields.differentNote
			}
			var title = this.formFields.differentNote
		} else {
			var metadata = {
			Name: this.formFields.fname + " " + this.formFields.lname,
			WorkshopID: this.formFields.id,
			Workshop: this.title,
			}
			var title = this.title
		}
		var items = [{ 
			cabin: this.formFields.selectedCabin,
			supplement: this.isPayingSingle,
			deposit: this.isPayingDeposit,
			second: this.isPayingSecondPayment,
			balance: this.isPayingBalance,
			other: this.isPayingOther,
			otherAmount: this.formFields.differentDollar.replace(/[&\/\\,$]/g, ''),
			email: this.formFields.email,
			title: title,
			customer: this.formFields.fname + " " + this.formFields.lname,
			data: metadata,
			inSalePeriod: this.formFields.inSalePeriod,
			customer: {
			address: {
				line1: this.formFields.addr1,
				line2: this.formFields.addr2,
				city: this.formFields.city,
				state: this.formFields.state,
				postal_code: this.formFields.zip,
			},
			email: this.formFields.email,
			name: this.formFields.fname + " " + this.formFields.lname
			}
		}]
		var data = JSON.stringify({
			items, query
		});
		var result = await sharedAPI.create(data);
		if(!result.data.clientSecret || result.data.error){
			let e = result.data.error
				let error = {
					displayError: "Credit card processor experienced an error please try again. If problem persists contact your bank or us at info@muenchworkshops.com",
					error: e,
			stripeResponse: result
				}
			//let stripeResponse = result
			throw error
		}
		this.paymentIntent.clientSecret =  result.data.clientSecret
		this.paymentIntent.status =  result.data.status
		this.paymentIntent.type =  result.data.type
		this.paymentIntent.code =  result.data.code
		this.paymentIntent.param =  result.data.param
		this.showStripeModal = true
		}
		catch(e){
		console.log(e)
		var message = await this.displayUserError(e)
		DOM.$message( {
			message: message,
			type: 'error',
			duration: 6000
		})
			let error = e.displayError ? e.error : e
		let params = {
			error: error,
			fullStripeResponse: e.stripeResponse,
			workshopDetails: this.formFields
		}
		sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
		}
		finally{
		this.loading = false
		}
	},


	async register(formFields) {
		var DOM = this;
		// var depositAmount = this.depositAmount;
		// var secondPaymentAmount = this.secondPaymentAmount;
		// var balanceAmount = this.balanceAmount;
		// //var onlineSaleDescription = DOM.onlineSaleDescription;
		var total = DOM.formFields.total
		var originalTotal = DOM.formFields.originalTotal
		// var originalDepositAmount = this.originalDepositAmount;
		// var originalSecondPaymentAmount = this.originalSecondPaymentAmount;
		// var originalBalanceAmount = this.originalBalanceAmount;
		var saleWasApplied = DOM.saleWasApplied
		// //Set Sale Bool
		if(!(total == originalTotal)){
			saleWasApplied = true
		}
		//Double checking that sale was applied is set properly
		DOM.formFields.saleWasApplied = saleWasApplied

		DOM.loadingText = "Sending...";
		var formValid = false

		let isValidEmail = await this.validEmail(this.formFields.email);
		if(!isValidEmail){
		DOM.formInvalid = true;
			this.$message({
			message: "Email Address is Invalid.",
			type: 'error',
			duration: 6000
			});
			return false
		};

		let isValidPhone = await this.validPhone(this.formFields.cell);
		if(!isValidPhone){
		DOM.formInvalid = true;
			this.$message({
			message: "Phone Number is Invalid.",
			type: 'error',
			duration: 6000
			});
			return false
		}

		try{
		DOM.$refs[formFields].validate((valid) => {
			if (valid) {
			formValid = true
			}
		})
		if(formValid){
			var pageData = this.formFields;
			pageData.title = this.title;
			pageData.token = this.token;
			pageData.isSoldOut = this.isSoldOut;
			pageData.comingSoon = this.comingSoon;
			pageData.form = this.code;
			pageData.tripDate = this.tripDate;
			pageData.cabinDesc = this.showCabin ? this.cabin[pageData.selectedCabin].desc : null;
			// pageData.depositAmount = this.cabin[pageData.selectedCabin].depositAmount;
			// pageData.secondPaymentAmount = this.cabin[pageData.selectedCabin].secondPaymentAmount;
			// pageData.balanceAmount = this.cabin[pageData.selectedCabin].balanceAmount;
			// pageData.total = this.cabin[pageData.selectedCabin].total;
			pageData.singleOccupancySupplement = this.singleOccupancySupplement;
			pageData.specialTerms = this.specialTerms;
			pageData.amount = this.amountPaying;
			var saleWasApplied = false;
			if(this.inSalePeriod){
			saleWasApplied = true;
			}
			//Sales Data
			// pageData.discountAmt = originalTotal - total;
			// pageData.depositAmount = depositAmount;
			// pageData.secondPaymentAmount = secondPaymentAmount;
			// pageData.balanceAmount = balanceAmount;
			// pageData.total = total;
			// pageData.originalDeposit = originalDepositAmount;
			// pageData.originalSecondPayment = originalSecondPaymentAmount;
			// pageData.originalBalance = originalBalanceAmount;
			// pageData.originalTotal = this.originalTotal;
			// pageData.onlineSaleDescription = this.onlineSaleDescription;
			// pageData.onlineSaleRate = this.onlineSaleRate;
			// pageData.saleWasApplied = saleWasApplied;

			var pageData = JSON.stringify(pageData);
			if (DOM.type == "register" || DOM.type == "waitlist"){            
			DOM.loading = true;
			var response = await sharedAPI.submitRegistration(pageData)
			var data = response.data

				//go to confirmation page
				var sr = JSON.parse(data.response.scriptResult)
				if (sr.code != undefined){
					//received response from FileMaker
					var params = {
					"result": sr,
					"fields": DOM.formFields,
					"balanceDueDate": DOM.balanceDueDate,
					"nonRefundable": DOM.nonRefundable,
					"waitlistPayment": DOM.waitlistMakePayment,
					"onlineSaleDescription": DOM.onlineSaleDescription,
					"saleWasApplied": saleWasApplied
					}
					// //Google Analytics
					if (DOM.$gtm.enabled()) {
					dataLayer.push({
						event: DOM.formFields.type == "register" ? "registrationCompleted" : "waitlistCompleted",
						"wis": {
						"transaction_id": sr.code,
						"value": DOM.cabin[DOM.formFields.selectedCabin].total,
						"items": {
							"item_id": DOM.formFields.id,
							"item_name": DOM.code,
							"item_category" : "Workshops",
							"item_category2": DOM.workshopRegion,
							"item_category3": DOM.workshopCountry,
							"item_category4": DOM.workshopState,
							"price": DOM.cabin[DOM.formFields.selectedCabin].total
						}
						}
					});
					}
				};
				}
				DOM.$router.push({name: 'confirmation', params: {'data': params}})
				}
			DOM.loading = false;
		}
		catch(e){
		console.log(e)
		var message = await this.displayUserError(e)
		DOM.$message({
			message: message, 
			type: 'error',
			duration: 6000
		})
		let params = {
			error: e,
			workshopDetails: this.formFields
		}
		sharedAPI.runScript('WebLog-SendErrorEmail', JSON.stringify(params))
		DOM.loading = false;
		}
		finally{
			this.loading = false
		}
	},
	checkEmail() {
		// No longer using person table for registrations
	},
	markInvalid() {
		if (this.formFields.agreeToTerms == false || this.formFields.agreeToTerms == null  || this.formFields.agreeToTerms == "" ){
		this.isInvalid.terms = true
		}
		if (this.formFields.agreeToFitness == false || this.formFields.agreeToFitness == null  || this.formFields.agreeToFitness == "" ){
		this.isInvalid.agreeToFitness = true
		}
		if (this.formFields.agreeToSpecialTerms == false || this.formFields.agreeToSpecialTerms == null  || this.formFields.agreeToSpecialTerms == "" ){
		this.isInvalid.specialTerms = true
		}
		if (this.formFields.fitnessLevel == false || this.formFields.fitnessLevel == null  || this.formFields.fitnessLevel == "" ){
		this.isInvalid.fitnessLevel = true
		}
		if (this.formFields.cameraKnowledge == false || this.formFields.cameraKnowledge == null  || this.formFields.cameraKnowledge == "" ){
		this.isInvalid.cameraKnowledge = true
		}
		if (this.formFields.paymentMethod == false || this.formFields.paymentMethod == null  || this.formFields.paymentMethod == "" ){
		this.isInvalid.paymentMethod = true
		}
		if (this.formFields.noncreditSRS == false || this.formFields.noncreditSRS == null  || this.formFields.noncreditSRS == "" ){
		this.isInvalid.noncreditSRS = true
		}
		
	},
	setDates(data) {
		var months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
		];
		var DOM = this;


		//BALANCE DUE DATE
		var start = data["DueDateBalancePayment"];
		var slash = start.indexOf("/");
		var startMonth = parseInt(start.substring(0, slash)) - 1;
		startMonth = months[startMonth];
		var rem = start.substring(slash + 1, start.length);
		slash = rem.indexOf("/");
		var startDay = rem.substring(0, slash);
		var leadingZero = startDay.charAt(0);
		if (leadingZero == 0) {
		startDay = startDay.substring(1, slash);}
		//start year
		rem = rem.substring(slash + 1, rem.length);
		var startYear = rem.substring(0, rem.length);
		DOM.balanceDueDate = startMonth + " " + startDay + ", " + startYear;

		//2ND PAYMENT DUE DATE
		if (data["DueDate2ndPayment"] != null){
		var start = data["DueDate2ndPayment"];
		var slash = start.indexOf("/");
		var startMonth = parseInt(start.substring(0, slash)) - 1;
		startMonth = months[startMonth];
		var rem = start.substring(slash + 1, start.length);
		slash = rem.indexOf("/");
		var startDay = rem.substring(0, slash);
		var leadingZero = startDay.charAt(0);
		if (leadingZero == 0) {
		startDay = startDay.substring(1, slash);}
		//start year
		rem = rem.substring(slash + 1, rem.length);
		var startYear = rem.substring(0, rem.length);
		DOM.secondPaymentDueDate = startMonth + " " + startDay + ", " + startYear;}


		//TRIP DATES
		//start month
		var start = data["DateStart"];
		var slash = start.indexOf("/");
		var startMonth = parseInt(start.substring(0, slash)) - 1;
		startMonth = months[startMonth];
		//start date
		var rem = start.substring(slash + 1, start.length);
		slash = rem.indexOf("/");
		var startDay = rem.substring(0, slash);
		var leadingZero = startDay.charAt(0);
		if (leadingZero == 0) {
		startDay = startDay.substring(1, slash);
		}
		//start year
		rem = rem.substring(slash + 1, rem.length);
		var startYear = rem.substring(0, rem.length);
		//end Date
		var end = data["DateEnd"];
		var slash = end.indexOf("/");
		var endMonth = parseInt(end.substring(0, slash)) - 1;
		var endMonth = months[endMonth];
		//end date
		var rem = end.substring(slash + 1, end.length);
		slash = rem.indexOf("/");
		var endDay = rem.substring(0, slash);
		var leadingZero = endDay.charAt(0);
		if (leadingZero == 0) {
		endDay = endDay.substring(1, slash);
		}
		//end year
		rem = rem.substring(slash + 1, rem.length);
		var endYear = rem.substring(0, rem.length);
		if (
		startDay == endDay &&
		startMonth == endMonth &&
		startYear == endYear
		) {
		DOM.tripDate = startMonth.trim() + " " + startDay.trim() + ", " + startYear.trim();
		} else if (
		startDay != endDay &&
		startMonth == endMonth &&
		startYear == endYear
		) {
		DOM.tripDate = startMonth + " " + startDay + "–" + endDay + ", " + startYear;
		} else if (startMonth != endMonth && startYear == endYear) {
		DOM.tripDate = startMonth + " " + startDay + " – " + endMonth + " " + endDay + ", " + startYear;
		} else if (startMonth != endMonth && startYear != endYear) {
		DOM.tripDate = startMonth + " " + startDay + ", " + startYear + " – " + endMonth + " " + endDay + ", " + endYear; }
	},
	/*
		querySearch, createFilter, and lo 
	*/
	querySearch(queryString, cb) {
		var links = this.countryList;
		var results = queryString ? links.filter(this.createFilter(queryString)) : links;
		// call callback function to return suggestions
		cb(results);
	},
	querySearchPhone(queryString, cb) {
		var links = this.countryPhoneList;
		var results = queryString ? links.filter(this.createFilterPhone(queryString)) : links;
		// call callback function to return suggestions
		cb(results);
	},
	createFilter(queryString) {
		return (link) => {
		return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
		};
	},
	createFilterPhone(queryString) {
		return (link) => {
		return (link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
		};
	},
	toCurrency(amt) {
		var formatCurrency = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		});
		var amount = formatCurrency.format(amt); 
		var splitAmount = amount.split(".")[0];
		return splitAmount;
	},
	toggleCheck (check){

		this.updateDisplayValues(false);
		var DOM = this
		if (!DOM.isPayingOther) {
			if (check == 'secondPayment'){
				DOM.isPayingSecondPayment = (DOM.isPayingSecondPayment) ? false : true;
				DOM.isPayingBalance = (DOM.isPayingSecondPayment) ? DOM.isPayingBalance : false;
			}
			if (check == 'balance'){
				DOM.isPayingBalance = (DOM.isPayingBalance) ? false : true;
			}
			if (check == 'single'){
				DOM.isPayingSingle = (DOM.isPayingSingle) ? false : true;
			}
		}
	},
	handleExistingRegistration: function(action){
		var DOM = this
		if (action == 'register') {
		DOM.formFields.type = 'register'
		}else if (action == 'pay') {
		DOM.formFields.type = 'pay'
		}
		DOM.showRegistrationModal = false
	},
	updateDisplayValues: function(forceOriginal){
		var DOM = this;
		
		//Reset to original values
		DOM.depositAmount = DOM.originalDepositAmount;
		DOM.secondPaymentAmount = DOM.originalSecondPaymentAmount;
		DOM.balanceAmount = DOM.originalBalanceAmount;
		DOM.total = DOM.originalTotal;

		//if we are in a sale and should show it
		if( DOM.inSalePeriod  && !(forceOriginal) ){
		//Never let total go below 0
		if (DOM.total - DOM.onlineSaleRate < 0){
			DOM.total = 0;
			DOM.depositAmount = 0;
			DOM.secondPaymentAmount = 0;
			DOM.balanceAmount = 0;
		}
		//If there is no balance amount
		else if ( DOM.balanceAmount <= 0 || DOM.balanceAmount == '' || DOM.isSinglePayment){
			DOM.depositAmount = DOM.depositAmount - DOM.onlineSaleRate;
			DOM.total = DOM.originalTotal - DOM.onlineSaleRate
		}
		//If there is a balance amount but the sale is greater
		else if( DOM.balanceAmount - DOM.onlineSaleRate <= 0){
			DOM.balanceAmount = 0;
			let balanceDifference = DOM.onlineSaleRate - DOM.balanceAmount;
			//If the sale isnt greater than the second payment
			if(DOM.secondPaymentAmount != 0 && DOM.secondPaymentAmount != null && DOM.secondPaymentAmount != undefined && (DOM.secondPaymentAmount - balanceDifference > 0)){
			DOM.secondPaymentAmount = DOM.secondPaymentAmount - balanceDifference;
			}
			//if the sale is greater than the second payment too
			else{
			let secondPaymentDifference = balanceDifference - DOM.secondPaymentAmount;
			DOM.secondPaymentAmount = 0;
			DOM.depositAmount = DOM.depositAmount - secondPaymentDifference
			}
			DOM.total = DOM.originalTotal - DOM.onlineSaleRate
			DOM.formFields.saleWasApplied = true
		}
		else{
			DOM.balanceAmount = DOM.originalBalanceAmount - DOM.onlineSaleRate;
			DOM.total = DOM.originalTotal - DOM.onlineSaleRate
		}
		}
		else{
		DOM.formFields.saleWasApplied = false
		}
		//Update the display values
		//Deposit
		DOM.depositDisplay = DOM.toCurrency(DOM.depositAmount);
		//Second Payment
		DOM.secondDisplay = DOM.toCurrency(DOM.Amount);
		if (DOM.secondPaymentAmount != "" && DOM.secondPaymentAmount != 0) {
		DOM.secondPaymentText = (
			"A second payment of " +
			DOM.secondPaymentDisplay +
			" is due on " +
			DOM.secondPaymentDueDate +
			".\n"
		);
		}
		//Total
		DOM.totalDisplay = DOM.toCurrency(DOM.total);
		//Balance
		DOM.balanceDisplay = DOM.toCurrency(DOM.balanceAmount);
		if (DOM.secondPaymentAmount != "" && DOM.secondPaymentAmount != 0) {
		DOM.balancePaymentText = (
			"The balance of " +
			DOM.balanceDisplay +
			" is due by " +
			DOM.balanceDueDate +
			".\n"
		);
		}
		//Update Form Fields
		DOM.formFields.depositAmount = DOM.depositAmount;
		DOM.formFields.secondPaymentAmount = DOM.secondPaymentAmount;
		DOM.formFields.balanceAmount = DOM.balanceAmount;
		DOM.formFields.total = DOM.total;
		DOM.formFields.originalDeposit = DOM.originalDepositAmount;
		DOM.formFields.originalSecondPayment = DOM.originalSecondPaymentAmount;
		DOM.formFields.originalBalance = DOM.originalBalanceAmount;
		DOM.formFields.originalTotal = DOM.originalTotal;
		DOM.formFields.onlineSaleDescription = DOM.onlineSaleDescription;
		DOM.formFields.onlineSaleRate = DOM.onlineSaleRate
	},
	phpTest: function(){
		var axios = require('axios')
		var query= [{ 
		FM_WS_F: this.code, 
		token: this.token 
		}]
		
		var items = [{ 
			cabin: this.formFields.selectedCabin,
			supplement: this.isPayingSingle,
			deposit: this.isPayingDeposit,
			second: this.isPayingSecondPayment,
			balance: this.isPayingBalance,
			other: this.isPayingOther,
			otherAmount: this.formFields.amountPaying,
			email: this.formFields.email,
			title: this.subtitle,
			data: this.formFields
		}]
		var data = JSON.stringify({
		items, query
		});
		var url = "create.php?";
		try{
		axios
		.post(url, data)
		.then(function(result) {
			// console.log(result.data.clientSecret)
			// DOM.clientSecret =  result.data.clientSecret//.json();
		})
		}catch (e){
		console.log(e)
		}
	},
	}
};
</script>
<style scoped>
textarea {
  padding-top: 10px;
}
.invalid {
  border: #f56c6c 1px solid;
  border-radius: 4px;
}
.el-select,
.el-date-editor {
  width: 100% !important;
}


</style>